$body-bg: #fff;
$body-color: #111;

html body {
  font-family: "Audiowide";
}

.iyNavLink {
  color: #555;
}

.iyNavLink:link {
  color: #555;
}

.iyNavLink:hover {
  color: rgba(238, 145, 23, 0.75);
}

.iyAnchor:link {
  color: #555;
}

.iyAnchor:hover {
  color: #444;
}

@import "~bootstrap/scss/bootstrap.scss";
@import url("https://fonts.googleapis.com/css?family=Audiowide&display=swap");
